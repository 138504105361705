<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <div class="py-1">
            <h5 class="font-weight-bold">Create Branch Subscription</h5>
            <p class="text--disabled font-weight-medium">
              Please check with the plan subscription with admin
            </p>
          </div>

          <v-autocomplete
            prepend-icon="mdi-domain"
            v-model="selectedCompany"
            label="Company"
            item-text="company_name"
            item-value="company_id"
            :items="companyList"
            :error-messages="selectedCompanyErrors"
            required
            @input="$v.selectedCompany.$touch()"
            @blur="$v.selectedCompany.$touch()"
          ></v-autocomplete>

          <v-text-field
            prepend-icon="mdi-store"
            v-model="bName"
            label="Branch Name"
            :error-messages="bNameErrors"
            required
            @input="$v.bName.$touch()"
            @blur="$v.bName.$touch()"
          ></v-text-field>

          <v-text-field
            prepend-icon="mdi-email"
            v-model="bEmail"
            label="Branch Email"
            :error-messages="bEmailErrors"
            @input="$v.bEmail.$touch()"
            @blur="$v.bEmail.$touch()"
          ></v-text-field>

          <v-text-field
            v-model="bPhone"
            label="Branch Phone"
            type="number"
            prepend-icon="mdi-phone"
            placeholder="016xxxxxxxx"
            :error-messages="bPhoneErrors"
            required
            @input="$v.bPhone.$touch()"
            @blur="$v.bPhone.$touch()"
          ></v-text-field>

          <v-text-field
            prepend-icon="mdi-map-marker"
            v-model="bAddress"
            label="Branch Address"
          ></v-text-field>

          <v-menu
            ref="smenu"
            v-model="stmenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="stime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="stime"
                label="Start Working Time"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                :error-messages="stimeErrors"
                required
                @input="$v.stime.$touch()"
                @blur="$v.stime.$touch()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="stmenu"
              v-model="stime"
              full-width
              @click:minute="$refs.smenu.save(stime)"
            ></v-time-picker>
          </v-menu>

          <v-menu
            ref="emenu"
            v-model="etmenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="etime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="etime"
                label="End Working Time"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                :error-messages="etimeErrors"
                required
                @input="$v.etime.$touch()"
                @blur="$v.etime.$touch()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="etmenu"
              v-model="etime"
              :min="stime"
              full-width
              @click:minute="$refs.emenu.save(etime)"
            ></v-time-picker>
          </v-menu>

          <v-select
          prepend-icon="mdi-file-sign"
            v-model="selectedPlan"
            :items="items"
            label="Subscription Plan"
            :item-text="getFieldText"
            return-object
            :error-messages="selectedPlanErrors"
            required
            @input="$v.selectedPlan.$touch()"
            @blur="$v.selectedPlan.$touch()"
          ></v-select>

          <v-btn class="mb-4" block @click="renew" color="primary"
            >create</v-btn
          >
        </v-card-text>

        <v-snackbar v-model="snackbar" top :color="sColor">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="" text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
    <script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  not,
} from "vuelidate/lib/validators";

export default {
  name: "create-branch",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "create-branch",
  },
  mixins: [validationMixin],

  validations: {
    selectedPlan: { required },
    selectedCompany: { required },
    bName: { required },
    bEmail: { email },
    bPhone: {
      minLength: minLength(10),
      maxLength: maxLength(11),
    },

    stime: { required },
    etime: { required, sameAs: not(sameAs("stime")) },
  },

  data() {
    return {
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      show: false,
      checkbox: false,
      message: "",
      selectedCompany: "",
      loading: false,
      snackbar: false,
      sColor: "",
      items: [],
      companyList: [],
      selectedPlan: "",
      stime: null,
      etime: null,
      stmenu: false,
      etmenu: false,
      bName: "",
      bEmail: "",
      bPhone: "",
      bAddress: "",
    };
  },
  created() {
    this.getAllCompany();
    this.getAllSubscriptionPlan();
  },

  computed: {
    selectedPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      !this.$v.selectedPlan.required &&
        errors.push("Subscription Plan is required.");
      return errors;
    },
    selectedCompanyErrors() {
      const errors = [];
      if (!this.$v.selectedCompany.$dirty) return errors;
      !this.$v.selectedCompany.required && errors.push("Company is required.");
      return errors;
    },
    bNameErrors() {
      const errors = [];
      if (!this.$v.bName.$dirty) return errors;
      !this.$v.bName.required && errors.push("Branch name is required");
      return errors;
    },
    bEmailErrors() {
      const errors = [];
      if (!this.$v.bEmail.$dirty) return errors;
      !this.$v.bEmail.email && errors.push("Please enter an valid email");
      return errors;
    },

    stimeErrors() {
      const errors = [];
      if (!this.$v.stime.$dirty) return errors;
      !this.$v.stime.required && errors.push("Start time is required");
      return errors;
    },

    etimeErrors() {
      const errors = [];
      if (!this.$v.etime.$dirty) return errors;
      !this.$v.etime.required && errors.push("End time is required");
      !this.$v.etime.sameAs &&
        errors.push("End time cannot be same as start time");
      return errors;
    },

    bPhoneErrors() {
      const errors = [];
      if (!this.$v.bPhone.$dirty) return errors;
      !this.$v.bPhone.minLength && errors.push("Phone must at least 10 number");
      !this.$v.bPhone.maxLength &&
        errors.push("Phone maximum consist of 11 number");
      return errors;
    },
  },
  methods: {
    getAllCompany() {
      const params = new URLSearchParams();
      params.append("read", "done");
      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.companyList = response.data.company;
          } else {
            this.companyList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllSubscriptionPlan() {
      const params = new URLSearchParams();
      params.append("readAllBranchSubscription", "done");
      axios({
        method: "post",
        url: this.domain + "/subscription/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.items = response.data.subscription;
          } else {
            this.items = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFieldText(item) {
      return `${item.subscription_package} - RM${item.subscription_fee}/${item.duration} months`;
    },

    renew() {
      var moment = require("moment");
      var startDate = moment().format("YYYY-MM-DD");
      var endDate = moment(startDate)
        .add(this.selectedPlan.duration, "M")
        .format("YYYY-MM-DD");

      this.$v.$touch();
      if (!this.$v.$invalid) {
        const params = new URLSearchParams();
        params.append("create_branch_subscription", "done");
        params.append("company_id", this.selectedCompany);
        params.append("branch_subscription_plan_id", this.selectedPlan.id);
        params.append(
          "subscription_package",
          this.selectedPlan.subscription_package
        );
        params.append("subscription_fee", this.selectedPlan.subscription_fee);
        params.append("duration", this.selectedPlan.duration);
        params.append("description", this.selectedPlan.description);
        params.append("start_date", startDate);
        params.append("end_date", endDate);
        params.append("bName", this.bName);
        params.append("bEmail", this.bEmail);
        params.append("bPhone", this.bPhone);
        params.append("bAddress", this.bAddress);
        params.append("start_time", this.stime);
        params.append("end_time", this.etime);

        axios({
          method: "post",
          url: this.domain + "/subscription/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.snackbar = true;
              this.sColor = "success";
              this.message = "Successfully create branch.";
              this.sendNotication(response.data.branch_id);
              this.selectedCompany = "";
              this.selectedPlan = "";
              this.bName = "";
              this.bEmail = "";
              this.bPhone = "";
              this.bAddress = "";
              this.stime = "";
              this.etime = "";
              this.$v.$reset();
            } else {
              this.snackbar = true;
              this.sColor = "danger";
              this.message = "Something went wrong. Please try again";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
    <style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
    